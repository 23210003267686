import Modal from '@src/components/Modal';
import React, { useState } from 'react';
import useSetting from '@src/hooks/useSetting';
import Link from '@src/components/Link';
import { useConnect } from 'redux-bundler-hook';
import useTranslations from '@src/hooks/useTranslations';
import { SITE_TYPES } from '@src/bundles/site';
import BEMHelper from 'react-bem-helper';
import { getServiceFromUrlNew, localizedUrl } from '@src/utils/navigate';
import { useAppDispatch } from '@src/app/hooks';
import { setLanguage } from '@src/app/features/language.slice';

const bem = new BEMHelper('header');

export const Languages = ({ languageArray, location }) => {
	const t = useTranslations();
	let service = getServiceFromUrlNew(location.pathname);
	const { doSetLanguage, language, siteType } = useConnect('doSetLanguage', 'selectLanguage', 'selectSiteType');
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const handleLanguageClick = () => {
		setShowLanguageModal(true);
	};
	const dispatch = useAppDispatch();
	return (
		languageArray.length > 1 && (
			<>
				<button onClick={handleLanguageClick}>
					<p {...bem('link', 'type-desktop', 'active')}>
						{language.toUpperCase()}
						{' - '}
						{t('kinto.menu.change-language')}
					</p>
				</button>
				<Modal
					open={showLanguageModal}
					handleClose={() => {
						setShowLanguageModal(false);
					}}
					xButton
					bemClass={'language'}
				>
					<h4 {...bem('language-modal-header')}>{t(`kinto.choose-language`)}</h4>
					{languageArray.map(item => {
						return (
							<Link
								{...bem('language-modal-language')}
								onClick={() => {
									setShowLanguageModal(false);
									doSetLanguage(item);
									dispatch(setLanguage(item));
								}}
								to={localizedUrl(siteType == SITE_TYPES.BUSINESS ? '/foretag' : '/', item, service)}
								key={item}
							>
								<h4>{t(`kinto.choose-language.${item}`)}</h4>
							</Link>
						);
					})}
				</Modal>
			</>
		)
	);
};
