import React, { useState, useRef, useEffect } from 'react';
import './Header.scss';
import BEMHelper from 'react-bem-helper';
import { useConnect } from 'redux-bundler-hook';
import { MenuList, MenuItem, Popper, Paper, ClickAwayListener } from '@mui/material';
import { smallDesktopSize } from '@src/javascript/variables';
import ArrowDropDown from '@src/assets/icons/arrow-drop-down.inline.svg';
import ArrowRight from '@src/assets/icons/arrow-right.inline.svg';
import Link from '@src/components/Link';

const bem = new BEMHelper('header');

const MainMenu = ({ items, onItemClick }) => {
	const { language } = useConnect('selectLanguage');
	if (!items) return <></>;

	const filteredItemsByLang = items.filter(item => item.language === language);

	return (
		<ul {...bem('main-menu')}>
			{filteredItemsByLang.map(item => (
				<li key={item.id} {...bem('menu-item')}>
					<MainMenuItem item={item} onItemClick={onItemClick} />
				</li>
			))}
		</ul>
	);
};

const MainMenuItem = ({ item, onItemClick }) => {
	const { language } = useConnect('selectLanguage');
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [isMobile, setIsMobile] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(typeof window === 'undefined' || window.innerWidth < smallDesktopSize);
		};
		window.addEventListener('resize', handleResize);
		setIsMobile(typeof window === 'undefined' || window.innerWidth < smallDesktopSize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const filteredNestedItemsByLang = item.language === language ? item.nestedLinks : null;

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
		onItemClick();
	};

	if (item.nestedLinks?.length > 0) {
		return !isMobile ? (
			<>
				<div
					{...bem(!open ? 'main-menu-container' : 'main-menu-container-active')}
					onClick={() => setOpen(!open)}
					ref={anchorRef}
				>
					{item.label}
					{<ArrowDropDown {...bem('dropdown-arrow')} />}
				</div>

				<Popper {...bem('dropdown')} placement="bottom-start" anchorEl={anchorRef.current} open={open}>
					<Paper>
						<ClickAwayListener onClickAway={handleClose}>
							<MenuList {...bem('menu-list')}>
								{filteredNestedItemsByLang.map(({ id, url, label }) => (
									<div key={id} {...bem('dropdown-menu-item')}>
										<Link {...bem('dropdown-link')} onClick={handleClose} to={url}>
											<MenuItem>{label}</MenuItem>
										</Link>
									</div>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Popper>
			</>
		) : (
			<ul {...bem('menu-item-mobile')} onClick={() => setOpen(!open)}>
				<li {...bem(!open ? 'main-menu-container' : 'main-menu-container-active')} key={item.id + 'mm-item'}>
					{item.label}
					<ArrowRight {...bem('dropdown-arrow')} />
				</li>
				<ul {...bem('nested-menu-item-container')} key={item.id + 'mm-item-nested'}>
					<li
						{...bem(open ? 'nested-menu-item-active' : 'nested-menu-item')}
						key={item.id + 'mm-item-nested-li'}
					>
						{filteredNestedItemsByLang.map(({ id, url, label }, index) => (
							<Link key={index} {...bem('link')} onClick={handleClose} to={url}>
								{label}
							</Link>
						))}
					</li>
				</ul>
			</ul>
		);
	}

	return (
		<Link onClick={handleClose} to={item.url} {...bem('link')}>
			{item.label}
		</Link>
	);
};

export default MainMenu;
